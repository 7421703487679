import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  mainFrame: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  pageTitle: {
    textAlign: 'center',
    marginBottom: theme.spacing(2),
  },
  mainGridContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: 300,
  },
  editableTextContainer: {
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  modifiedTextField: {
    borderColor: '#ff9800', // Change the border color or apply any other styles
  },
}));
