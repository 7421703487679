import { Button, Checkbox, CircularProgress, FormControlLabel, Grid, Link, TextField, Tooltip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { FormEvent, KeyboardEventHandler, useCallback, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { postToEndpoint } from '../AxiosHelper';
import { isEmailAddressValid, isPasswordValid } from '../Helper';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  gridContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(2),
    width: '15rem'
  },
  buttonContainer: {
    display: 'flex',
    gap: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
}));

export type LoginPageProps = {
  onLogin: (token: string, navigationStateFromRedirection: string) => void;
}

function LoginPage(props: LoginPageProps): JSX.Element {
  const { onLogin } = props;
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const classes = useStyles();
  const [isLoggingIn, setIsLoggingIn] = useState<boolean>(false);
  const [email, setEmail] = useState('');
  const [pw, setPw] = useState('');
  const [pwAgain, setPwAgain] = useState('');
  const [isSignup, setIsSignup] = useState(false);
  const [isAgreed, setIsAgreed] = useState(false);
  const [isSigningUp, setIsSigningUp] = useState(false);
  const [isPwRequesting, setIsPwRequesting] = useState(false);
  const [isPwRequestDone, setIsPwRequestDone] = useState(false);
  const [isHideError, setIsHideError] = useState(true);
  const [requestErrorLabel, setRequestErrorLabel] = useState('');
  const [showVerificationEmailSent, setShowVerificationEmailSent] = useState(false);

  const isEmailValid = isEmailAddressValid(email);
  const isPwValid = isPasswordValid(pw);
  const isPwAgainValid = pw === pwAgain;
  const isLoginValid = isEmailValid && isPwValid;
  const isSignupValid = isLoginValid && isPwAgainValid && isAgreed;
  const isPwRequestValid = isEmailValid;
  const isBusy = isLoggingIn || isSigningUp || isPwRequesting;

  const emailErrorLabel = useMemo(() => {
    if (email === '') {
      return 'user.login.fail.email_empty';
    }

    if (!isEmailValid) {
      return 'user.login.fail.email_invalid';
    }

    return '';
  }, [email, isEmailValid]);

  const pwErrorLabel = useMemo(() => {
    if (pw === '') {
      return 'user.login.fail.password_empty';
    }

    if (!isPwValid) {
      return 'user.login.fail.password_invalid';
    }

    return '';
  }, [isPwValid, pw])

  const pwAgainErrorLabel = isPwAgainValid ? '' : 'user.signup.fail.password_again_not_match';
  const isAgreedColor = isHideError || isAgreed ? 'primary' : 'error';

  useEffect(() => {
    if (showVerificationEmailSent) {
      setIsSignup(false);
    }
  }, [showVerificationEmailSent]);

  //reset isAgreed, passwordAgain when signup starts. Hide error when switching
  useEffect(() => {
    if (isSignup) {
      setPwAgain('');
      setIsAgreed(false);
      setShowVerificationEmailSent(false);
    }
    setIsHideError(true);
    setRequestErrorLabel('');
  }, [isSignup])

  const handleSubmitClick = useCallback(() => {
    setIsHideError(false);
    setRequestErrorLabel('');
    setShowVerificationEmailSent(false);

    if (isSignup) {
      if (isSignupValid) {
        setIsSigningUp(true);
        postToEndpoint('signup', { email: email, password: pw, locale: i18n.language })
          .then(() => {
            setShowVerificationEmailSent(true);
          })
          .catch((error) => {
            const response = error.response;
            let errorLabel = 'user.signup.fail.general_issue';
            if (axios.isAxiosError(error) && response?.status === 409) {
              errorLabel = 'user.signup.fail.already_exists';
            }
            setRequestErrorLabel(errorLabel);
          })
          .finally(() => setIsSigningUp(false))
      }
    } else {
      if (isLoginValid) {
        setIsLoggingIn(true);
        postToEndpoint('login', { email: email, password: pw })
          .then((response) => {
            onLogin(response?.data.token, location.state?.from || '/');
          })
          .catch((error) => {
            const response = error.response;
            let errorLabel = 'user.login.fail.general_issue';
            if (axios.isAxiosError(error)) {
              if (response?.status === 401) {
                errorLabel = 'user.login.fail.bad_credential';
              } else if (response?.status === 412) {
                errorLabel = 'user.login.fail.unverified';
              }
            }
            setRequestErrorLabel(errorLabel);
          })
          .finally(() => setIsLoggingIn(false))
      }
    }
  }, [isSignup, isSignupValid, email, pw, i18n.language, isLoginValid, onLogin, location.state?.from]);

  const handlePwRequestBtnClick = useCallback(() => {
    setIsHideError(false);
    setRequestErrorLabel('');
    setShowVerificationEmailSent(false);
    if (isPwRequestValid) {
      setIsPwRequesting(true);
      postToEndpoint('pwrequest', { email: email, locale: i18n.language })
        .then(() => {
          setIsPwRequestDone(true);
        })
        .catch((error) => {
          const response = error.response;
          let errorLabel = 'user.pwrequest.fail.general_issue';
          if (axios.isAxiosError(error)) {
            if (response?.status === 404) {
              errorLabel = 'user.pwrequest.fail.unknown_email';
            }
          }
          setRequestErrorLabel(errorLabel);
        })
        .finally(() => setIsPwRequesting(false))
    }
  }, [email, i18n.language, isPwRequestValid]);

  const handleGDPRDownloadClick = useCallback(() => {
    // Create a hidden link element
    const link = document.createElement('a');
    link.href = 'https://feedbackpack.eu/wp-content/uploads/2024/04/GDPR_HU_v1.pdf';
    link.download = 'gdpr_hu.pdf';

    // Trigger a click on the link to start the download
    link.click();
  }, []);

  const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleSubmitClick();
    }
  }, [handleSubmitClick]);

  function handleSubmit(event: FormEvent<HTMLFormElement>): void {
    handleSubmitClick();
    event.preventDefault();
  }

  //By default go to monitor-list if there was no redirection to login earlier.
  //Otherwise go to the page from which we were redirected to login

  return <form className={classes.root} onSubmit={handleSubmit}>
    <Grid container className={classes.gridContainer}>
      <Grid item>
        <Typography variant="h5" className={classes.title} style={{ width: '100%' }}>
          {t(isSignup ? 'user.signup.title' : 'user.login.title')}
        </Typography>
      </Grid>
      {(showVerificationEmailSent || isPwRequestDone) && <Grid item>
        <Typography variant="body2" style={{ width: '100%', color: "green" }}>
          <Trans i18nKey={showVerificationEmailSent ? "user.signup.email_sent" : 'user.pwrequest.done'} components={{ 1: <br /> }} />
        </Typography>
      </Grid>}
      <Grid item style={{ width: '100%' }}>
        <TextField
          fullWidth
          label="Email *"
          variant="outlined"
          name='username'
          type="email"
          error={!isHideError && !isEmailValid}
          helperText={isHideError ? '' : t(emailErrorLabel)}
          title={email === '' ? t('required_field_title') : ''}
          autoComplete="email"
          value={email}
          onKeyDown={handleKeyDown}
          onChange={(e) => setEmail(e.target.value)} />
      </Grid>
      <Grid item style={{ width: '100%' }}>
        <TextField
          fullWidth
          label={t('user.login.password') + ' *'}
          name='password'
          type="password"
          variant="outlined"
          error={!isHideError && !isPwValid}
          autoComplete="current-password"
          title={pw === '' ? t('required_field_title') : ''}
          value={pw}
          helperText={isHideError ? '' : t(pwErrorLabel)}
          onKeyDown={handleKeyDown}
          onChange={(e) => setPw(e.target.value)} />
      </Grid>
      {isSignup ?
        <Grid item style={{ width: '100%' }}>
          <TextField
            fullWidth
            label={t('user.signup.password_again') + ' *'}
            type="password"
            variant="outlined"
            error={!isHideError && !isPwAgainValid}
            value={pwAgain}
            title={pwAgain === '' ? t('required_field_title') : ''}
            helperText={isHideError ? '' : t(pwAgainErrorLabel)}
            onKeyDown={handleKeyDown}
            onChange={(e) => setPwAgain(e.target.value)} />
        </Grid> : <></>}
      {isSignup ? <Grid item style={{ width: '100%' }}>
        <FormControlLabel control={<Checkbox
          color="primary"
          checked={isAgreed}
          onChange={() => setIsAgreed(!isAgreed)}
        />} label={
          <Typography style={{ fontSize: 'small' }} color={isAgreedColor}>
            {t('user.signup.agree_static')}
            <Link href="#" onClick={handleGDPRDownloadClick} color={isAgreedColor}>
              {t('user.signup.agree_dynamic') + ' *'}.
            </Link>
          </Typography>
        } />
      </Grid> : <></>}
      {requestErrorLabel !== '' && <Grid item>
        <Typography variant="body2" style={{ width: '100%' }} color="error">
          {t(requestErrorLabel)}
        </Typography>
      </Grid>}
      <Grid item style={{ width: '100%' }}>
        <Button variant="contained" color="primary" type="submit" disabled={isBusy} fullWidth>
          {isBusy ? <CircularProgress /> : t(isSignup ? 'user.signup.signup_btn' : 'user.login.login_btn')}
        </Button>
      </Grid>
      {!isSignup ? <Grid item style={{ width: '100%' }}>
        <Tooltip title={isPwRequestDone ? t('user.pwrequest.done_tooltip') : ''}>
          <span>
            <Button size='small' variant="contained" color="secondary" disabled={isBusy || isPwRequestDone} onClick={handlePwRequestBtnClick} fullWidth>
              {isBusy ? <CircularProgress /> : t('user.pwrequest.btn')}
            </Button>
          </span>
        </Tooltip>
      </Grid> : <></>}
      <Grid item style={{ width: '100%' }}>
        <Button size='small' variant="contained" color="secondary" disabled={isBusy} onClick={() => setIsSignup(!isSignup)} fullWidth>
          {isBusy ? <CircularProgress /> : t(isSignup ? 'user.signup.to_login_btn' : 'user.login.to_signup_btn')}
        </Button>
      </Grid>
    </Grid>
  </form >;
};

export default LoginPage;
