import './BatteryIconStyles.css'; // Replace with the path to your CSS file

function BatteryIcon(level: number): JSX.Element {
    // Ensure the level is within the valid range (0-5)
    const validatedLevel = Math.min(Math.max(level, 0), 100);
    let colorClass = '';
    if (validatedLevel > 15) {
        colorClass = 'green';
    } else if (validatedLevel > 10) {
        colorClass = 'yellow';
    } else {
        colorClass = 'red';
    }

    return (
        <div>
            <div className="battery-body">
                <div className={`battery-level ${colorClass}`} style={{ width: validatedLevel + "%" }}></div>
                <div className="battery-tip"></div>
            </div>
        </div>
    );
};

export default BatteryIcon;
