import { HashRouter } from 'react-router-dom';
import App from './App';

function Main() {
  return (
    <HashRouter>
      <App />
    </HashRouter>
  );
}

export default Main;