import axios, { AxiosResponse } from "axios";
import config from "./config";

export const getFromEndpointWithToken = (endpoint: string, token: string): Promise<AxiosResponse<any, any>> => {
  return axios.get(config.BACKEND_URL + endpoint, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  })
}

export const postToEndpoint = (endpoint: string, content: any): Promise<AxiosResponse<any, any>> => {
  return axios.post(config.BACKEND_URL + endpoint, content);
}

export const postToEndpointWithToken = (endpoint: string, token: string, content: any): Promise<AxiosResponse<any, any>> => {
  return axios.post(config.BACKEND_URL + endpoint,
    content,
    {
      headers: {
        'Authorization': `Bearer ${token}`
      },
    });
}
