import { Accordion, AccordionDetails, AccordionSummary, Button, CircularProgress, FormControl, Grid, IconButton, Link, Paper, Table, TableBody, TableCell, TableRow, TextField, Tooltip, Typography } from '@material-ui/core';
import { Add, Delete, Remove, RemoveCircle } from '@material-ui/icons';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import axios from 'axios';
import { KeyboardEventHandler, useCallback, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { postToEndpointWithToken } from '../AxiosHelper';
import { DeviceDescriptor } from '../Device';
import { getDeviceStateForDeviceId, getDeviceStatusColor, getLocalDateStringFromUtcDateString, getOrderedOldDeviceDescriptors, getOrderedRecentDeviceDescriptors, isEmailAddressValid } from '../Helper';
import { MonitoringSystemDescriptor, MonitoringSystemState } from '../MonitoringSystem';
import { User } from '../User';
import { useStyles } from '../styles';
import BatteryIcon from './BatteryIcon';
import EditableText from './EditableText';
import './MonitoringSystemPage.css';

interface MonitoringSystemPageProps {
  token: string,
  user: User,
  selectedMonitoringSystemDescriptor: MonitoringSystemDescriptor,
  selectedMonitoringSystemState: MonitoringSystemState,
  updateMonitoringSystemDescriptorRequest: () => void,
  onLogout: () => void
}

function MonitoringSystemPage(props: MonitoringSystemPageProps): JSX.Element {
  const { token, user, selectedMonitoringSystemDescriptor, selectedMonitoringSystemState, updateMonitoringSystemDescriptorRequest, onLogout } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const location = useLocation();
  const addingCodeFromUrl = new URLSearchParams(location.search).get('addingCode');

  const [expandedIndex, setExpandedIndex] = useState<number | undefined>(undefined);
  const [topupCode, setTopupCode] = useState<string>('');
  const [topupErrorLabel, setTopupErrorLabel] = useState<string>('');
  const [isToppingUp, setIsToppingUp] = useState<boolean>(false);
  const [addingCode, setAddingCode] = useState<string>('');
  const [addDeviceErrorLabel, setAddDeviceErrorLabel] = useState<string>('');
  const [isAddingDevice, setIsAddingDevice] = useState<boolean>(false);
  const [newGuestEmail, setNewGuestEmail] = useState<string>('');
  const [addRemoveGuestErrorLabel, setAddRemoveGuestErrorLabel] = useState<string>('');
  const [isAddingGuest, setIsAddingGuest] = useState<boolean>(false);
  const [isRemovingGuest, setIsRemovingGuest] = useState<boolean>(false);
  const [leaveErrorLabel, setLeaveErrorLabel] = useState<string>('');

  const isNewGuestEmailValid = useMemo(() => {
    return isEmailAddressValid(newGuestEmail);
  }, [newGuestEmail])

  const orderedGuests = useMemo(() => {
    return selectedMonitoringSystemDescriptor.guests.sort((g1, g2) => g1.email.localeCompare(g2.email));
  }, [selectedMonitoringSystemDescriptor.guests])

  const handleAccordionChange = useCallback((index: number) => {
    setExpandedIndex((prevIndex) => (prevIndex === index ? undefined : index));
  }, []);

  const orderedRecentDeviceDescriptors = useMemo(() => getOrderedRecentDeviceDescriptors(selectedMonitoringSystemDescriptor, selectedMonitoringSystemState), [selectedMonitoringSystemDescriptor, selectedMonitoringSystemState]);
  const orderedOtherDeviceDescriptors = useMemo(() => getOrderedOldDeviceDescriptors(selectedMonitoringSystemDescriptor, selectedMonitoringSystemState), [selectedMonitoringSystemDescriptor, selectedMonitoringSystemState]);
  const orderedMergedDeviceDescriptors = useMemo(() =>
    orderedOtherDeviceDescriptors.length === selectedMonitoringSystemDescriptor.deviceDescriptors.length ?
      orderedOtherDeviceDescriptors : (orderedOtherDeviceDescriptors.length === 0 ?
        orderedRecentDeviceDescriptors : undefined), [orderedOtherDeviceDescriptors, orderedRecentDeviceDescriptors, selectedMonitoringSystemDescriptor.deviceDescriptors.length]); //it should be shown if not undefined. Otherwise the recent and the other categories separately

  const addDeviceExpandedIndex = useMemo(() => {
    return selectedMonitoringSystemDescriptor.deviceDescriptors.length;
  }, [selectedMonitoringSystemDescriptor.deviceDescriptors]);

  const guestsExpandedIndex = addDeviceExpandedIndex + 1;

  const handleTopupClick = useCallback(() => {
    if (topupCode !== '') {
      setIsToppingUp(true);
      postToEndpointWithToken('topupMonitoringSystem', token, { code: topupCode })
        .then(() => {
          updateMonitoringSystemDescriptorRequest();
          setTopupCode('');
          setTopupErrorLabel('');
        })
        .catch((error) => {
          const response = error.response;
          let errorLabel = 'monitoring_system_page.topup.fail.unknown_reason';
          if (axios.isAxiosError(error)) {
            if (response?.status === 401 || response?.status === 403) {
              onLogout();
            } else if (response?.status === 404 || response?.status === 412) {
              errorLabel = 'monitoring_system_page.topup.fail.bad_code';
            } else if (response?.status === 409) {
              errorLabel = 'monitoring_system_page.topup.fail.used_code';
            }
          }
          setTopupErrorLabel(errorLabel);
        })
        .finally(() => setIsToppingUp(false))
    }
  }, [onLogout, token, topupCode, updateMonitoringSystemDescriptorRequest])

  const handleTopupInputKeyDown: KeyboardEventHandler<HTMLDivElement> = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
    setTopupErrorLabel('');
    if (e.key === "Enter") {
      handleTopupClick();
    }
  }, [handleTopupClick]);

  const handleAddDeviceClick = useCallback(() => {
    if (addingCode !== '') {
      setIsAddingDevice(true);
      postToEndpointWithToken('addDevice', token, { addingCode: addingCode })
        .then(() => {
          setAddDeviceErrorLabel('');
          updateMonitoringSystemDescriptorRequest();
        })
        .catch((error) => {
          const response = error.response;
          let errorLabel = 'monitoring_system_page.add_device.fail.unknown_reason';
          if (axios.isAxiosError(error)) {
            if (response?.status === 401 || response?.status === 403) {
              onLogout();
            } else if (response?.status === 404) {
              errorLabel = 'monitoring_system_page.add_device.fail.bad_code';
            } else if (response?.status === 409) {
              errorLabel = 'monitoring_system_page.add_device.fail.used_code';
            }
          }
          setAddDeviceErrorLabel(errorLabel);
        })
        .finally(() => setIsAddingDevice(false))
    }
  }, [addingCode, onLogout, token, updateMonitoringSystemDescriptorRequest])

  const handleAddingCodeInputKeyDown: KeyboardEventHandler<HTMLDivElement> = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
    setAddDeviceErrorLabel('');
    if (e.key === "Enter") {
      handleAddDeviceClick();
    }
  }, [handleAddDeviceClick]);

  const handleAddGuestClick = useCallback(() => {
    if (isNewGuestEmailValid) {
      setIsAddingGuest(true);
      postToEndpointWithToken('inviteGuest', token, { email: newGuestEmail })
        .then(() => {
          setAddRemoveGuestErrorLabel('');
          setNewGuestEmail('');
          updateMonitoringSystemDescriptorRequest();
        })
        .catch((error) => {
          const response = error.response;
          let errorLabel = 'monitoring_system_page.guests.fail.unknown_reason';
          if (axios.isAxiosError(error)) {
            if (response?.status === 401 || response?.status === 403) {
              onLogout();
            } else if (response?.status === 404) {
              errorLabel = 'monitoring_system_page.guests.fail.not_exist';
            } else if (response?.status === 409) {
              errorLabel = 'monitoring_system_page.guests.fail.already_added';
            }
          }
          setAddRemoveGuestErrorLabel(errorLabel);
        })
        .finally(() => setIsAddingGuest(false))
    }
  }, [isNewGuestEmailValid, newGuestEmail, onLogout, token, updateMonitoringSystemDescriptorRequest])

  const handleGuestEmailInputKeyDown: KeyboardEventHandler<HTMLDivElement> = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
    setAddRemoveGuestErrorLabel('');
    if (e.key === "Enter") {
      handleAddGuestClick();
    }
  }, [handleAddGuestClick]);

  const handleRemoveGuestClick = useCallback((email: string) => {
    setIsRemovingGuest(true);
    postToEndpointWithToken('removeGuest', token, { email: email })
      .then(() => {
        setAddRemoveGuestErrorLabel('');
        updateMonitoringSystemDescriptorRequest();
      })
      .catch((error) => {
        const response = error.response;
        let errorLabel = 'monitoring_system_page.guests.fail.remove';
        if (axios.isAxiosError(error)) {
          if (response?.status === 401 || response?.status === 403) {
            onLogout();
          }
        }
        setAddRemoveGuestErrorLabel(errorLabel);
      })
      .finally(() => setIsRemovingGuest(false))
  }, [onLogout, token, updateMonitoringSystemDescriptorRequest]);

  const onLeave = useCallback(() => {
    postToEndpointWithToken('leaveMonitoringSystem', token, { monitoringSystemId: selectedMonitoringSystemDescriptor.monitoringSystemId })
      .then(() => {
        setLeaveErrorLabel('');
        updateMonitoringSystemDescriptorRequest();
      })
      .catch((error) => {
        const response = error.response;
        let errorLabel = 'monitoring_system_page.guests.fail.leave';
        if (axios.isAxiosError(error)) {
          if (response?.status === 401 || response?.status === 403) {
            onLogout();
          }
        }
        setLeaveErrorLabel(errorLabel);
      })
  }, [onLogout, selectedMonitoringSystemDescriptor.monitoringSystemId, token, updateMonitoringSystemDescriptorRequest]);

  //close the expanded item when changing between monitoring systems (but do not close when only a property of the same monitoring system changes)
  useEffect(() => {
    if (selectedMonitoringSystemDescriptor?.monitoringSystemId !== undefined) {
      setExpandedIndex(undefined)
    }
  }, [selectedMonitoringSystemDescriptor?.monitoringSystemId])

  useEffect(() => {
    if (addingCodeFromUrl !== null) {
      setAddingCode(addingCodeFromUrl);
    }
  }, [addingCodeFromUrl])

  useEffect(() => {
    if (addingCodeFromUrl !== null) {
      setExpandedIndex(addDeviceExpandedIndex);
    }
  }, [addDeviceExpandedIndex, addingCodeFromUrl]);

  useEffect(() => {
    if (addingCodeFromUrl !== null && expandedIndex === addDeviceExpandedIndex) {
      setTimeout(() => {
        window.scrollTo({ top: document.documentElement.scrollHeight, behavior: 'smooth' });
      }, 200); // Timeout needed for the accordion to open and the stuff tu be rendered
    }
  }, [addDeviceExpandedIndex, addingCodeFromUrl, expandedIndex])

  const handleDeviceRename = useCallback((index: number, newName: string) => {
    const deviceId = index > orderedRecentDeviceDescriptors.length - 1 ? orderedOtherDeviceDescriptors[index - orderedRecentDeviceDescriptors.length].deviceId : orderedRecentDeviceDescriptors[index].deviceId;
    postToEndpointWithToken('renameDevice', token, { deviceId: deviceId, name: newName })
      .then(() => {
        updateMonitoringSystemDescriptorRequest();
      })
      .catch((error) => {
        if (axios.isAxiosError(error)) {
          //the only reason for an error here is that we are logged out -> we log out
          onLogout();
        }
      })
  }, [onLogout, orderedOtherDeviceDescriptors, orderedRecentDeviceDescriptors, token, updateMonitoringSystemDescriptorRequest]);


  const BUDGET_WARN_DAYS = 14;
  const budgetDaysLeft = selectedMonitoringSystemState.monitoringDaysBudget / Math.max(orderedRecentDeviceDescriptors.length, 1);
  const budgetColor = selectedMonitoringSystemState.monitoringDaysBudget <= 0 ? "#d32f2f" : (budgetDaysLeft <= BUDGET_WARN_DAYS ? "#ff9800" : "rgba(0, 0, 0, 0.87)");

  const getDeviceGridItems = useCallback((deviceDescriptors: DeviceDescriptor[], initIndex: number) => {
    ;
    return deviceDescriptors.map((deviceDescriptor, index) => {
      const globalIndex = initIndex + index;
      const deviceState = getDeviceStateForDeviceId(deviceDescriptor.deviceId, selectedMonitoringSystemState);
      return <Grid item style={{ margin: 5, width: '100%' }} key={deviceDescriptor.deviceId}>
        <Tooltip title={deviceState === undefined ? t('device.disabled_tooltip') : ''}>
          <span>
            <Accordion
              expanded={expandedIndex === globalIndex}
              onChange={() => {
                if (deviceState !== undefined) {
                  handleAccordionChange(globalIndex)
                }
              }}
            >
              <AccordionSummary expandIcon={deviceState === undefined ? <RemoveCircle /> : <ExpandMoreIcon />}>
                <Grid container alignItems='center' justifyContent='center'>
                  <Grid item xs={1}>
                    {deviceState !== undefined && <span className={"circle " + getDeviceStatusColor(deviceState.status) + "-circle"}></span>}
                  </Grid>
                  <Grid item xs={2}>
                    {deviceState !== undefined && BatteryIcon(deviceState.battery)}
                  </Grid>
                  <Grid item xs={9}>
                    {selectedMonitoringSystemDescriptor.owner.email === user.email ?
                      <EditableText value={deviceDescriptor.name} onSave={(newName) => handleDeviceRename(globalIndex, newName)} /> :
                      <Typography>{deviceDescriptor.name}</Typography>}
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                {deviceState &&
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell variant="body" width={"60%"}>{t('device.serial')}</TableCell>
                        <TableCell variant="body">{deviceDescriptor.deviceId}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell variant="body">{t('device.status.title')}</TableCell>
                        <TableCell variant="body">{t('device.status.' + deviceState.status.toLowerCase())}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell variant="body">{t('device.battery')}</TableCell>
                        <TableCell variant="body">{deviceState.battery}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell variant="body">{t('device.temperature')}</TableCell>
                        <TableCell variant="body">{deviceState.temperature}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell variant="body">{t('device.signal_strength')}</TableCell>
                        <TableCell variant="body">{deviceState.signalStrength}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell variant="body">{t('device.last_seen')}</TableCell>
                        <TableCell variant="body">{deviceState === undefined ? undefined : getLocalDateStringFromUtcDateString(deviceState.lastSeen)}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>}
              </AccordionDetails>
            </Accordion>
          </span>
        </Tooltip>
      </Grid>
    });
  }, [expandedIndex, handleAccordionChange, handleDeviceRename, selectedMonitoringSystemDescriptor.owner.email, selectedMonitoringSystemState, t, user.email]);

  return <FormControl className={classes.mainFrame}>
    <Typography variant="h5" className={classes.pageTitle} style={{ width: '300px' }}>
      <Trans>{selectedMonitoringSystemDescriptor.name}</Trans>
    </Typography>
    <Grid container className={classes.mainGridContainer}>
      <Grid item style={{ width: '100%', marginTop: 10 }}>
        <Accordion
          expanded={expandedIndex === -1}
          onChange={() => handleAccordionChange(-1)}
          style={{ marginBottom: 10 }}
        >
          <AccordionSummary
            title={(budgetDaysLeft <= 0) ? t('monitoring_system_page.topup.no_budget_tooltip') : ''}
            expandIcon={expandedIndex === -1 ? <Remove /> : <Add />

            }>
            <Grid container alignItems='center' justifyContent='flex-end'>
              <Grid item xs={10}>
                <Typography style={{ fontWeight: 'bold' }}>{t('monitoring_system_page.topup.budget')}</Typography>
              </Grid>
              <Grid item xs={2} style={{ textAlign: 'right' }}>
                <Tooltip title={budgetDaysLeft <= 0 ? t('monitoring_system_page.topup.no_budget_tooltip') : budgetDaysLeft <= BUDGET_WARN_DAYS ? t('monitoring_system_page.topup.warn_budget_tooltip', { daysLeft: budgetDaysLeft }) : ""}>
                  <Typography style={{
                    color: budgetColor,
                    fontWeight: 'bold'
                  }}>
                    {selectedMonitoringSystemState.monitoringDaysBudget}
                  </Typography>
                </Tooltip>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container alignItems='center' justifyContent='center' spacing={2} direction="column">
              <Grid item style={{ width: '100%' }}>
                <TextField
                  label={t('monitoring_system_page.topup.input') + ' *'}
                  variant="outlined"
                  value={topupCode}
                  error={topupErrorLabel !== ''}
                  helperText={t(topupErrorLabel)}
                  onKeyDown={handleTopupInputKeyDown}
                  onChange={(e) => setTopupCode(e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item style={{ width: '100%' }}>
                <Tooltip title={topupCode === '' ? t('monitoring_system_page.topup_btn.tooltip') : ''}>
                  <span>
                    <Button variant="contained"
                      disabled={topupCode === '' || isToppingUp}
                      color="primary"
                      onClick={handleTopupClick}
                      style={{ width: '100%', display: 'block', textAlign: 'center' }}
                      fullWidth
                    >
                      {isToppingUp ? <CircularProgress /> : t('monitoring_system_page.topup.btn')}
                    </Button>
                  </span>
                </Tooltip>
              </Grid>
              <Grid item style={{ width: '100%' }}>
                <Typography variant='caption'>
                  <Link href="https://shop.feedbackpack.eu/products/100-megfigyelesi-nap" target="_blank" rel="noopener noreferrer" >
                    {t('monitoring_system_page.topup.webshop_link')}
                  </Link>
                </Typography>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
      <Paper style={{ width: '100%', marginBottom: 10, borderRadius: 10 }}>
        {orderedMergedDeviceDescriptors !== undefined ?
          <Paper style={{ margin: 5, borderRadius: 10 }}>
            <Grid container direction='column'>
              <Grid item>
                <Typography style={{ fontWeight: 'bold', margin: 5 }}>
                  {t('monitoring_system_page.devices_title')}
                </Typography>
              </Grid>
              <Grid container>
                {orderedMergedDeviceDescriptors.length === 0 ?
                  <Typography style={{ margin: 5 }}>
                    {t('monitoring_system_page.no_device')}
                  </Typography> :
                  getDeviceGridItems(orderedMergedDeviceDescriptors, 0)}
              </Grid>
            </Grid>
          </Paper>
          : <div>
            < Paper style={{ margin: 5, borderRadius: 10 }}>
              <Grid container direction='column'>
                <Grid item>
                  <Typography style={{ fontWeight: 'bold', margin: 5 }}>
                    {t('monitoring_system_page.recent_devices_title')}
                  </Typography>
                </Grid>
                <Grid container>
                  {getDeviceGridItems(orderedRecentDeviceDescriptors, 0)}
                </Grid>
              </Grid>
            </Paper>
            <Paper style={{ margin: 5, borderRadius: 10 }}>
              <Grid container direction='column'>
                <Grid item>
                  <Typography style={{ fontWeight: 'bold', margin: 5 }}>
                    {t('monitoring_system_page.old_devices_title')}
                  </Typography>
                </Grid>
                <Grid container>
                  {getDeviceGridItems(orderedOtherDeviceDescriptors, orderedRecentDeviceDescriptors.length)}
                </Grid>
              </Grid>
            </Paper>
          </div>
        }
        {user.email === selectedMonitoringSystemDescriptor.owner.email && <Grid item style={{ width: '100%' }}>
          <Accordion
            expanded={expandedIndex === addDeviceExpandedIndex}
            onChange={() => handleAccordionChange(addDeviceExpandedIndex)}
            style={{ margin: 10 }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Grid container alignItems='center' justifyContent='center'>
                <Grid item sm={9}>
                  <Typography>{t('monitoring_system_page.add_device.title')}</Typography>
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container alignItems='center' justifyContent='center' spacing={2} direction="column">
                <Grid item style={{ width: '100%' }}>
                  <TextField
                    label={t('monitoring_system_page.add_device.input_help')}
                    variant="outlined"
                    value={addingCode}
                    error={addDeviceErrorLabel !== ''}
                    helperText={t(addDeviceErrorLabel)}
                    onKeyDown={handleAddingCodeInputKeyDown}
                    onChange={(e) => setAddingCode(e.target.value)}
                    fullWidth
                  />
                </Grid>
                <Grid item style={{ width: '100%' }}>
                  <Tooltip title={addingCode === '' ? t('monitoring_system_page.add_device.btn_tooltip') : ''}>
                    <span>
                      <Button variant="contained"
                        disabled={addingCode === '' || isAddingDevice}
                        color="primary"
                        onClick={handleAddDeviceClick}
                        style={{ width: '100%', display: 'block', textAlign: 'center' }}
                        fullWidth
                      >
                        {isAddingDevice ? <CircularProgress /> : t('monitoring_system_page.add_device.btn')}
                      </Button>
                    </span>
                  </Tooltip>
                </Grid>
                <Grid item style={{ width: '100%' }}>
                  <Typography variant='caption'>
                    <Link href="https://shop.feedbackpack.eu/products/solo-sam-vadcsapda-megfigyelo-120-ajandek-megfigyelesi-nappal" target="_blank" rel="noopener noreferrer" >
                      {t('monitoring_system_page.add_device.webshop_link')}
                    </Link>
                  </Typography>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>}
      </Paper>
      {
        user.email === selectedMonitoringSystemDescriptor.owner.email ? <Grid item style={{ width: '100%' }}>
          <Accordion
            expanded={expandedIndex === guestsExpandedIndex}
            onChange={() => handleAccordionChange(guestsExpandedIndex)}
            title={t('monitoring_system_page.guests.tooltip')}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Grid container alignItems='center' justifyContent='center'>
                <Grid item sm={9}>
                  <Typography style={{ fontWeight: 'bold', textAlign: 'center' }}>{t('monitoring_system_page.guests.title')}</Typography>
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container alignItems='center' justifyContent='center' direction="column">
                {selectedMonitoringSystemDescriptor.guests.length === 0 ?
                  <Grid item style={{ marginBottom: 30 }}>
                    <Typography>{t('monitoring_system_page.guests.no_guest')}</Typography>
                  </Grid> :
                  orderedGuests.map((guest) => {
                    return <Paper style={{ width: '100%', marginBottom: 10, borderRadius: 10 }}>
                      <Grid container style={{ width: '100%' }} alignItems='center' justifyContent='space-between'>
                        <Grid item>
                          <Typography style={{ marginLeft: 15 }}>{guest.email}</Typography>
                        </Grid>
                        <Grid item>
                          <Tooltip title={!isNewGuestEmailValid ? t('monitoring_system_page.guests.delete_btn_tooltip') : ''}>
                            <span>
                              <IconButton onClick={() => handleRemoveGuestClick(guest.email)} >
                                {isRemovingGuest ? <CircularProgress /> : <Delete />}
                              </IconButton>
                            </span>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </Paper>
                  })}
                <Grid container alignItems='center' justifyContent='center' direction="column">
                  <Grid item style={{ width: '100%' }}>
                    <TextField
                      label={t('monitoring_system_page.guests.input')}
                      variant="outlined"
                      value={newGuestEmail}
                      error={addRemoveGuestErrorLabel !== ''}
                      helperText={t(addRemoveGuestErrorLabel)}
                      onKeyDown={handleGuestEmailInputKeyDown}
                      onChange={(e) => setNewGuestEmail(e.target.value)}
                      fullWidth
                    />
                  </Grid>
                  <Grid item style={{ width: '100%', marginTop: 10 }}>
                    <Tooltip title={!isNewGuestEmailValid ? t('monitoring_system_page.guests.btn_tooltip') : ''}>
                      <span>
                        <Button variant="contained"
                          disabled={!isNewGuestEmailValid || isAddingGuest}
                          color="primary"
                          onClick={handleAddGuestClick}
                          style={{ width: '100%', display: 'block', textAlign: 'center' }}
                          fullWidth
                        >
                          {isAddingGuest ? <CircularProgress /> : t('monitoring_system_page.guests.btn')}
                        </Button>
                      </span>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid> :
          <Grid container alignItems='center' justifyContent='center' direction='column' style={{ width: '100%' }}>
            <Grid item>
              <Typography style={{ color: 'red' }}>{t(leaveErrorLabel)}</Typography>
              <Grid />
              <Grid item>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => onLeave()}
                  style={{ width: '100%' }}>
                  {t('monitoring_system_page.guests.leave')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
      }
    </Grid >
  </FormControl >
}

export default MonitoringSystemPage;
