import { Checkbox, FormControlLabel, Grid, Table, TableBody, TableCell, TableRow, Tooltip, Typography } from "@material-ui/core";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import { DeviceWithState } from "../Device";
import { getDeviceStatusColor, isDeviceStateRecent } from "../Helper";

interface MapPageProps {
  devicesWithState: DeviceWithState[]
}

const MapPage = (props: MapPageProps): JSX.Element => {
  const { devicesWithState: devices } = props;
  const { t, i18n } = useTranslation();
  const [showOldDevice, setShowOldDevice] = useState(false);

  const devicesToShow = useMemo(() => {
    return showOldDevice ? devices : devices.filter(d => isDeviceStateRecent(d.deviceState));
  }, [devices, showOldDevice]);

  const zoom = useMemo(() => {
    if (devicesToShow.length === 0) {
      return 7; // Default zoom level in case there is no device to show
    }

    // Calculate bounding box
    let minLat = devicesToShow[0].deviceState.latitude!;
    let maxLat = minLat;
    let minLng = devicesToShow[0].deviceState.longitude!;
    let maxLng = minLng;

    devicesToShow.forEach(deviceWithState => {
      const [lat, lng] = [deviceWithState.deviceState.latitude!, deviceWithState.deviceState.longitude!];
      minLat = Math.min(minLat, lat);
      maxLat = Math.max(maxLat, lat);
      minLng = Math.min(minLng, lng);
      maxLng = Math.max(maxLng, lng);
    });

    // Calculate optimal zoom level
    const mapWidth = window.innerWidth; // or any other relevant dimension
    const mapHeight = window.innerHeight; // or any other relevant dimension
    const latRange = maxLat - minLat;
    const lngRange = maxLng - minLng;
    const MAX_ZOOM_LEVEL = 18; // Max zoom level of Leaflet. The higher the number, the more we are zoomed in.
    const latZoomLevel = latRange === 0 ? MAX_ZOOM_LEVEL : Math.log2(360 * (mapHeight / latRange / 256)); // 256 is the height of a tile
    const lngZoomLevel = lngRange === 0 ? MAX_ZOOM_LEVEL : Math.log2(180 * (mapWidth / lngRange / 256)); // 256 is the width of a tile
    return Math.min(latZoomLevel, lngZoomLevel, MAX_ZOOM_LEVEL);
  }, [devicesToShow]);

  const center = useMemo(() => {
    if (devicesToShow.length === 0) {
      return [46.850384, 19.083496]; // Default center point
    }

    let minLat = devicesToShow[0].deviceState.latitude!;
    let maxLat = minLat;
    let minLng = devicesToShow[0].deviceState.longitude!;
    let maxLng = minLng;

    devicesToShow.forEach(deviceWithState => {
      const [lat, lng] = [deviceWithState.deviceState.latitude!, deviceWithState.deviceState.longitude!];
      minLat = Math.min(minLat, lat);
      maxLat = Math.max(maxLat, lat);
      minLng = Math.min(minLng, lng);
      maxLng = Math.max(maxLng, lng);
    })
    return [(minLat + maxLat) / 2, (minLng + maxLng) / 2];
  }, [devicesToShow])

  return (
    <div style={{ height: "100vh" }}>
      <Tooltip title={t('map.only_show_recent_tooltip')}>
        <span>
          <FormControlLabel
            control={<Checkbox
              checked={!showOldDevice}
              onChange={() => setShowOldDevice(v => !v)}
            />}
            label={t('map.only_show_recent_text')} />
        </span>
      </Tooltip>
      <MapContainer center={[center[0], center[1]]} zoom={zoom} style={{ height: "100%" }}>
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        {devicesToShow.map((deviceWithState, index) => {
          const color = getDeviceStatusColor(deviceWithState.deviceState.status);
          // Custom pin icon
          const pinIcon = new L.Icon({
            iconUrl: require('../pic/' + color + '_pin.png'),
            iconSize: [32, 32],
            iconAnchor: [16, 32],
            popupAnchor: [0, -32],
          })

          return (
            <Marker key={index} position={[deviceWithState.deviceState.latitude!, deviceWithState.deviceState.longitude!]} icon={pinIcon}>
              <Popup>
                <Grid container style={{ justifyContent: "center" }}>
                  <Grid item>
                    <Typography>{deviceWithState.deviceDescriptor.name}</Typography>
                  </Grid>
                  <Grid item>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell variant="body">{i18n.t('device.status.title')}</TableCell>
                          <TableCell variant="body">{i18n.t('device.status.' + deviceWithState.deviceState.status.toLowerCase())}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell variant="body">{i18n.t('device.location')}</TableCell>
                          <TableCell variant="body">
                            {deviceWithState.deviceState.latitude}
                            <br />
                            {deviceWithState.deviceState.longitude}
                            <br />
                            <a href={`https://www.google.com/maps/dir/?api=1&destination=${deviceWithState.deviceState.latitude},${deviceWithState.deviceState.longitude}&travelmode=driving`} target="_blank" rel="noopener noreferrer">
                              {i18n.t('device.googleMaps')}
                            </a>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell variant="body">{i18n.t('device.battery')}</TableCell>
                          <TableCell variant="body">{deviceWithState.deviceState.battery}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Grid>
                </Grid>
              </Popup>
            </Marker>
          );
        })}
      </MapContainer>
    </div >
  );
};

export default MapPage;
