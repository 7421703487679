import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

i18next.use(initReactI18next).use(LanguageDetector).init({
  fallbackLng: 'en',
  resources: {
    hu: {
      translation: {
        device: {
          serial: 'Sorszám',
          status: {
            title: 'Állapot',
            unknown: 'Nem kommunikál',
            active: 'Aktív',
            off: 'Fogott és lekapcsolt',
          },
          battery: 'Akku [%]',
          temperature: 'Hőmérséklet [°C]',
          signal_strength: 'Térerő [%]',
          latitude: 'Szélesség [°]',
          longitude: 'Hosszúság [°]',
          location: 'Helyadatok',
          googleMaps: 'Ugrás Google Térképre',
          last_seen: 'Állapotfrissítés',
          disabled_tooltip: 'Az eszköz még nem jelezte állapotát a központnak',
        },
        default_ms_name: '{{userName}} rendszere',
        own_ms_name: 'Saját rendszerem',
        default_device_name: '{{id}} eszköz',
        monitoring_system_page:
        {
          disabled_tooltip: 'Nem vagy bejelentkezve',
          devices_title: 'Eszközök',
          recent_devices_title: 'Az elmúlt 3 napban aktív eszközök',
          old_devices_title: 'További eszközök',
          topup: {
            budget: 'Megfigyelési napok',
            input: 'Feltöltési kód',
            btn: 'Feltöltés',
            btn_tooltip: 'Adj meg egy feltöltési kódot!',
            no_budget_tooltip: 'A megfigyelési napok elfogytak! Feltöltésükig rendszered nem működik.',
            warn_budget_tooltip: 'A megfigyelési napok hamarosan elfogynak (~{{daysLeft}} nap múlva)! Töltsd fel őket, hogy rendszered zavartalanul működjön!',
            webshop_link: 'Nincs még kódod? Irány a webshop!',
            fail: {
              bad_code: 'A feltöltési kód érvénytelen.',
              used_code: 'A feltöltési kódot már használták.',
              unknown_reason: 'A feltöltés sikertelen volt. Próbálkozz később!',
            }
          },
          no_device: 'Nincs még eszköz hozzáadva a rendszerhez.',
          add_device: {
            title: 'Eszköz hozzáadása',
            input_help: 'Hozzáadási kód',
            btn: 'Hozzáadás',
            btn_tooltip: 'Add meg a hozzáadási kódot a terméken található szalagról!',
            webshop_link: 'Kell még eszköz? Irány a webshop!',
            fail: {
              bad_code: 'A hozzáadási kód érvénytelen.',
              used_code: 'A hozzáadási kódot már használták.',
              unknown_reason: 'A hozzáadás sikertelen volt. Próbálkozz később!',
            }
          },
          guests: {
            title: 'Vendégek',
            input: 'Meghívandó vendég email címe',
            btn: 'Vendég meghívása',
            tooltip: 'A vendégek látják a tulajdonos rendszerében levő eszközök állapotát és értesítéseket kapnak tőlük.',
            btn_tooltip: 'A vendég email címe helytelen!',
            delete_btn_tooltip: 'Vendég törlése',
            no_guest: 'Még nincsen meghívott vendég.',
            leave: 'Rendszer elhagyása',
            fail: {
              not_exist: 'A megadott email címmel nincs regisztrált felhasználó.',
              already_added: 'A megadott email címmel már van hozzáadva vendég.',
              unknown_reason: 'A meghívás sikertelen volt. Próbálkozz később!',
              remove: 'A vendég törlése sikertelen volt. Próbálkozz később!',
              leave: 'A rendszer elhagyása sikertelen volt. Próbálkozz később!',
            }
          }
        },
        map: {
          disabled_tooltip: 'Nincs még helyadat eszközről',
          only_show_recent_text: 'Csak az elmúlt 3 napban aktív eszközök',
          only_show_recent_tooltip: 'Csak az elmúlt 3 napban aktív eszköz kerül megmutatásra a térképen, a többi rejtve marad'
        },
        user: {
          login: {
            title: 'Bejelentkezés',
            login_btn: 'Bejelentkezés',
            to_signup_btn: 'Regisztrációhoz ->',
            password: 'Jelszó',
            fail: {
              email_empty: 'Email cím hiányzik.',
              email_invalid: 'Email cím helytelen.',
              password_empty: 'Jelszó hiányzik.',
              password_invalid:
                'A jelszó legalább 6 karakter hosszú.',
              bad_credential:
                'Bejelentkezés sikertelen. Ellenőrizd a belépési adatokat!',
              unverified: 'Bejelentkezés sikertelen. A regisztráció megerősítésre vár.',
              general_issue: 'Bejelentkezés sikertelen. Próbálkozz később!',
            }
          },
          signup: {
            title: 'Regisztráció',
            to_login_btn: '<- Bejelentkezéshez',
            signup_btn: 'Regisztráció',
            password_again: 'Jelszó újra',
            agree_static: 'Elfogadom az ',
            agree_dynamic: 'adatkezelést',
            email_sent: 'Regisztráció folyamatban!<1/>Erősítsd meg a regisztrációt az emailben kapott utasítások alapján!',
            fail: {
              password_again_not_match: 'A megadott jelszavak nem egyeznek.',
              already_exists: 'Ezzel az email címmel már van regisztrált felhasználó.',
              not_agreed: 'Adatkezelési beleegyezés szükséges.',
              general_issue: 'Regisztráció sikertelen. Próbálkozz később!',
            }
          },
          pwrequest: {
            btn: 'Elfelejtett jelszó',
            done: 'A jelszót kiküldtük az email címre.',
            done_tooltip: 'A jelszóemlékeztető elküldésre került a megadott email címre.',
            fail: {
              unknown_email: 'A megadott email címmel nincsen regisztrált felhasználó.',
              general_issue: 'A jelszó lekérdezése sikertelen. Próbálkozz később!'
            }
          },
          verify: {
            btn: 'Tovább a bejelentkezéshez',
            success: 'Sikeres regisztráció!',
            fail: {
              general_error: 'A regisztráció megerősítése sikertelen.',
              not_valid: 'A regisztráció megerősítése nem lehetséges, ismételt regisztráció szükséges.',
            }
          },
          settings: {
            title: 'Felhasználói beállítások',
            user_name: 'Felhasználónév',
            email: 'Email',
            pw: 'Új jelszó',
            pw_again: 'Új jelszó újra',
            phone: 'Telefonszám',
            phone_not_available: 'Adj meg egy érvényes telefonszámot az opció aktiválásához!',
            notif: {
              event_based: 'Reggeli jelentés',
              event_based_title: 'Az opció kiválasztásával minden nap érkezik jelentés minden aktív eszköz állapotáról. Aktív eszköz az, amely az elmúlt 3 napban jelezte állapotát.',
              is_email: 'Értesítés emailben',
              is_phone: 'Értesítés SMS-ben',
              language: 'Értesítés nyelve',
              topup: {
                budget: 'SMS-ek száma',
                no_sms_balance_tooltip: 'Az SMS-ek elfogytak! Feltöltésükig email értesítéseket kapsz.',
                warn_sms_balance_tooltip: 'Az SMS-ek hamarosan elfogynak (~{{daysLeft}} nap múlva)! Töltsd fel őket, hogy megszakítás nélkül kaphass SMS értesítéseket!',
                input: 'Feltöltési kód',
                btn: 'Feltöltés',
                btn_tooltip: 'Adj meg egy feltöltési kódot!',
                fail: {
                  bad_code: 'A feltöltési kód érvénytelen.',
                  used_code: 'A feltöltési kódot már használták.',
                  unknown_reason: 'A feltöltés sikertelen volt. Próbálkozz később!',
                }
              }
            },
            save_btn: 'Mentés',
            save_disabled_tooltip_no_change: 'Nincs elmenthető változás.',
            save_disabled_tooltip_error: 'Hibás beállítások nem menthetőek.',
            logout_btn: 'Kijelentkezés',
            update_success: 'A beállítások mentése sikeres.',
            delete: {
              btn: 'Felhasználó törlése',
              confirm_title: 'Felhasználói fiók törlésének megerősítése',
              confirm_body: 'Biztosan törölni szeretnéd felhasználói fiókodat? A törléssel minden beállítás elveszik, monitorozó rendszered a megmaradt megfigyelési napokkal együtt törlésre kerül. Kérjük add meg jelszavadat a megerősítéshez:',
              pw: 'Jelszó',
              confirm_ack_btn: 'Törlés',
              confirm_cancel_btn: 'Vissza',
              fail: {
                not_allowed: 'A megadott jelszó helytelen.',
                has_devices: 'A felhasználó nem törölhető, amíg a monitorozó rendszeréhez eszközök vannak rendelve.',
                negative_budget: 'A felhasználó nem törölhető, amíg a monitorozó rendszerének büdzséje negatív.',
                unknown_error: 'A felhasználó törlése sikertelen. Próbálkozz később!',
              },
            },
            credentials: {
              btn: 'Bejelentkezési adatok módosítása',
              confirm_title: 'Bejelentkezési adatok módosításának megerősítése',
              confirm_body: 'A bejelentkezési adatok (email cím és/vagy jelszó) módosításához add meg a jelszavadat:',
              pw: 'Jelszó',
              confirm_ack_btn: 'Mentés',
              confirm_cancel_btn: 'Vissza',
              fail: {
                not_allowed: 'A megadott jelszó helytelen.',
                unknown_error: 'Az adatok módosítása sikertelen. Próbálkozz később!'
              }
            },
            fail: {
              name_empty: "Felhasználónév hiányzik.",
              name_too_long: "Felhasználónév max. {{maxLength}} karakter hosszú lehet.",
              phone_invalid: "A telefonszám helytelen.",
              name_exists: "A megadott névvel már létezik felhasználó.",
              not_allowed: 'A megadott jelszó helytelen.',
              unknown_error: "A beállítások mentése sikertelen. Próbálkozz később!"
            }
          }
        },
        select_ms_prompt: 'Válassz monitorozó rendszert!',
        not_logged_in: 'Nem vagy bejelentkezve',
        select_mon_sys: 'Válassz rendszert!',
        required_field_title: 'Töltsd ki ezt a mezőt!',
      }
    },
    en: {
      translation: {
        device: {
          serial: 'Serial',
          status: {
            title: 'Status',
            unknown: 'Not communicating!',
            active: 'Active',
            off: 'Triggered and turned off!',
          },
          battery: 'Battery [%]',
          temperature: 'Temperature [°C]',
          signal_strength: 'Signal [%]',
          latitude: 'Latitude [°]',
          longitude: 'Longitude [°]',
          last_seen: 'Status update',
        },
        monitoring_system_page:
          { budget: '{{monitoringDays}} monitoring days remaining' },
        manage_devices: 'Manage Devices',
        login: {
          title: 'Login',
          signup_btn: 'Sign up',
          login_btn: 'Log in',
          logout_btn: 'Log out',
          password: 'Password',
          logged_in_as: 'User: "{{user}}"',
          fail: {
            email_empty: 'No email provided',
            email_invalid: 'Provided email is not valid',
            password_empty: 'No password provided',
            password_invalid: 'Password should be at least 6 characters long',
            credential_problem: 'Login failed. Please check your credentials!',
            general_issue: 'Login failed. Please try again later!',
          }
        },
        select_ms_prompt: 'Please select a monitoring system',
        not_logged_in: 'Not logged in',
        select_mon_sys: 'Select monitoring system'
      }
    }
  }
})
