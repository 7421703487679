interface Config {
  BACKEND_URL: string;
}

const config: Config = {
  // PROD
  BACKEND_URL: "https://backend.feedbackpack.eu:6655/sampoint/",
  // LOCAL DEV
  //BACKEND_URL: "https://localhost:6655/sampoint/",
};

export default config;
