import { AppBar, Grid, IconButton, Toolbar, Tooltip, Typography, makeStyles } from '@material-ui/core';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import LoginPage from './pages/LoginPage';
import MonitoringSystemPage from './pages/MonitoringSystemPage';

import { faHouseSignal, faMapLocationDot, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import axios, { AxiosResponse } from 'axios';
import { useTranslation } from 'react-i18next';
import { getFromEndpointWithToken } from './AxiosHelper';
import { DeviceWithState } from './Device';
import { isStringNullUndefinedOrEmpty } from './Helper';
import { MonitoringSystemDescriptor, MonitoringSystemState } from './MonitoringSystem';
import { MonitoringSystemSelector } from './MonitoringSystemSelector';
import { User } from './User';
import MapPage from './pages/MapPage';
import SettingsPage from './pages/SettingsPage';
import VerifyPage from './pages/VerifyPage';

const VERSION = require('../package.json').version;

type LanguageInfo = {
  nativeName: string;
  flag: string;
};

const langs: Record<string, LanguageInfo> = {
  hu: { nativeName: 'Magyar', flag: '🇭🇺' },
  en: { nativeName: 'English', flag: '🇬🇧' },
}

const useStyles = makeStyles((theme) => ({
  title: {
    width: '100%',
    flexGrow: 1,
  },
  iconImage: {
    height: 24, // Set the desired height of the icon image
    width: 24, // Set the desired width of the icon image
  },
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(1),
  },
}));

function App(): JSX.Element {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const [token, setToken] = useState<string>(localStorage.getItem('fbpAuthToken') ?? '');
  const [user, setUser] = useState<User | undefined>(undefined);
  const [monitoringSystemDescriptors, setMonitoringSystemDescriptors] = useState<MonitoringSystemDescriptor[]>([]);
  const [selectedMonitoringSystemDescriptorId, setSelectedMonitoringSystemDescriptorId] = useState<number | undefined>(undefined);
  const [selectedMonitoringSystemState, setSelectedMonitoringSystemState] = useState<MonitoringSystemState | undefined>(undefined);
  const location = useLocation();
  const navigate = useNavigate();

  const handleError = useCallback((error: any) => {
    if (axios.isAxiosError(error)) {
      //any axios error means logout on this level
      setToken('');
    }
  }, []);

  const ownMonitoringSystemId = useMemo(() => {
    return monitoringSystemDescriptors.findIndex((system) => system.owner.email === user?.email);
  }, [monitoringSystemDescriptors, user?.email]);

  const ownMonitoringSystemDescriptor = ownMonitoringSystemId === -1 ? undefined : monitoringSystemDescriptors[ownMonitoringSystemId];

  const defaultMonitoringSystemDescriptorId = useMemo(() => {
    if (user === undefined || monitoringSystemDescriptors.length === 0) {
      return undefined;
    }

    if (ownMonitoringSystemDescriptor !== undefined) {
      //if there is an own system, we use that as default
      return ownMonitoringSystemDescriptor.monitoringSystemId;
    }

    //if there is no own monitoring system, we make the user select one
    return undefined;
  }, [monitoringSystemDescriptors.length, ownMonitoringSystemDescriptor, user]);

  const getFromEndpointForToken = useCallback((endpoint: string): Promise<AxiosResponse<any, any>> => {
    return getFromEndpointWithToken(endpoint, token);
  }, [token]);

  const pollMonitoringSystemState = useCallback(() => {
    if (selectedMonitoringSystemDescriptorId !== undefined) {
      getFromEndpointForToken('getMonitoringSystemState?monitoringSystemId=' + selectedMonitoringSystemDescriptorId)
        .then((response) => {
          setSelectedMonitoringSystemState(response?.data);
        })
        .catch(handleError)
    }
  }, [getFromEndpointForToken, handleError, selectedMonitoringSystemDescriptorId]);

  const updateUser = useCallback(() => {
    getFromEndpointForToken('getCurrentUser')
      .then((response) => {
        let user = response?.data as User;

        if (isStringNullUndefinedOrEmpty(user.nickName)) {
          //set the first part of the email address as a default user name if it not yet set
          user.nickName = user.email.split('@')[0];
        }

        if (isStringNullUndefinedOrEmpty(user.preferredLanguage)) {
          //set the default language for the use if no preferred language is set yet
          user.preferredLanguage = Object.keys(langs)[0];
        }

        setUser(user);
      })
      .catch(handleError);
  }, [getFromEndpointForToken, handleError]);

  //update user once the token changes
  useEffect(() => {
    localStorage.setItem('fbpAuthToken', token);
    if (token !== '') {
      updateUser();
    } else {
      setUser(undefined);
    }
  }, [token, updateUser])

  //Poll monitoring system state periodically
  useEffect(() => {
    pollMonitoringSystemState();//do it once on page open and then periodically

    const interval = setInterval(() => {
      pollMonitoringSystemState();
    }, 5000);

    return () => {
      clearInterval(interval);
    }
  }, [pollMonitoringSystemState]);

  const updateMonitoringSystemDescriptors = useCallback(() => {
    getFromEndpointForToken('getMonitoringSystemDescriptors')
      .then((descriptors) => {
        const newDs = (descriptors.data as MonitoringSystemDescriptor[]).map((d) => {
          const newD = d;

          if (isStringNullUndefinedOrEmpty(newD.owner.nickName)) {
            //if owner's user name is missing, we generate it just like for the current user
            newD.owner.nickName = newD.owner.email.split('@')[0];
          }

          newD.guests = newD.guests.map((g) => {
            const newG = g;
            if (isStringNullUndefinedOrEmpty(newG.nickName)) {
              //if guest's user name is missing, we generate it just like for the current user
              newG.nickName = newG.email.split('@')[0];
            }
            return newG;
          });

          //always override monitoring system name based on user name
          newD.name = newD.owner.email === user?.email ? t('own_ms_name') : t('default_ms_name', { userName: newD.owner.nickName });

          newD.deviceDescriptors = newD.deviceDescriptors.map((dd) => {
            const newDD = dd;
            if (isStringNullUndefinedOrEmpty(newDD.name)) {
              //if device name is missing, we generate it based on its ID
              newDD.name = t('default_device_name', { id: newDD.deviceId })
            }
            return newDD;
          })

          return newD;
        });

        setMonitoringSystemDescriptors(newDs);
      })
      .catch(handleError);
  }, [getFromEndpointForToken, handleError, t, user?.email])

  //obtain monitoring system descriptors once a new user is present
  //clear monitoring system descriptors once a user is not present anymore
  useEffect(() => {
    if (user !== undefined) {
      updateMonitoringSystemDescriptors();
    } else {
      setMonitoringSystemDescriptors([]);
    }
  }, [updateMonitoringSystemDescriptors, user]);

  //select own monitoring system once a monitoring system is present or when the monitoringSystemDescriptors get updated
  //clear own monitoring system, once the monitor system is not present anymore
  useEffect(() => {
    if (monitoringSystemDescriptors.length === 0) {
      setSelectedMonitoringSystemDescriptorId(undefined);
    } else {
      setSelectedMonitoringSystemDescriptorId(defaultMonitoringSystemDescriptorId);
    }
  }, [defaultMonitoringSystemDescriptorId, monitoringSystemDescriptors])

  /*TODO reactivate when English language is complete
    const setNextLanguage = useCallback(() => {
      const languages = Object.keys(langs);
      const index = languages.findIndex((lan) => lan === i18n.language);
      const nextIndex = (index + 1) % languages.length;
      i18n.changeLanguage(languages[nextIndex]);
    }, [i18n]);
    */

  //set Hungarian as language for now
  useEffect(() => {
    i18n.changeLanguage(Object.keys(langs)[0]);
  }, [i18n])

  const selectedMonitoringSystemDescriptor = useMemo(() => {
    return monitoringSystemDescriptors.find(ms => ms.monitoringSystemId === selectedMonitoringSystemDescriptorId);
  }, [monitoringSystemDescriptors, selectedMonitoringSystemDescriptorId]);

  const getDeviceDescriptorForDeviceId = useCallback((id: number) => {
    return selectedMonitoringSystemDescriptor?.deviceDescriptors.find(dd => dd.deviceId === id);
  }, [selectedMonitoringSystemDescriptor?.deviceDescriptors]);

  const deviceWithStatesWithLocation = useMemo(() => {
    const deviceStatesWithLocation = selectedMonitoringSystemState?.deviceStates.filter((ds) => ds.latitude !== null && ds.latitude !== undefined && ds.longitude !== null && ds.longitude !== undefined && getDeviceDescriptorForDeviceId(ds.deviceId) !== undefined);

    if (deviceStatesWithLocation === undefined) {
      return [];
    }

    return deviceStatesWithLocation.map((ds) => {
      const deviceDescriptor = getDeviceDescriptorForDeviceId(ds.deviceId);
      return { deviceDescriptor: deviceDescriptor, deviceState: ds } as DeviceWithState;
    })
  }, [getDeviceDescriptorForDeviceId, selectedMonitoringSystemState?.deviceStates])

  useEffect(() => {
    console.log("Version: " + VERSION);
  }, [])

  return (
    <div className={classes.paper}>
      <AppBar position="sticky" style={{ marginBottom: 10 }}>
        <Toolbar>
          <Grid container alignItems='center' justifyContent='flex-start'>
            <Grid item xs={3}>
              <Typography variant="h6" className={classes.title}>
                Sam Point
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Grid container alignItems='center' justifyContent='flex-end'>
                {monitoringSystemDescriptors.length > 1 &&
                  <Grid item>
                    <MonitoringSystemSelector
                      onSelectionChange={(msdid) => {
                        setSelectedMonitoringSystemDescriptorId(msdid);
                        navigate('/monitor-list')
                      }}
                      selectedMonitoringSystemId={selectedMonitoringSystemDescriptor?.monitoringSystemId}
                      ownMonitoringSystemId={ownMonitoringSystemDescriptor?.monitoringSystemId}
                      monitoringSystemDescriptorsForSelection={monitoringSystemDescriptors.map(ms => { return { id: ms.monitoringSystemId, name: ms.name } })} />
                  </Grid>
                }
                <Grid item>
                  <Tooltip title={selectedMonitoringSystemDescriptor === undefined ? t('monitoring_system_page.disabled_tooltip') : ''}>
                    <span>
                      <IconButton edge="end" color="inherit" component={Link} disabled={selectedMonitoringSystemDescriptor === undefined} to="/monitor-list">
                        <FontAwesomeIcon icon={faHouseSignal} />
                      </IconButton>
                    </span>
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Tooltip title={selectedMonitoringSystemDescriptor === undefined ? t('monitoring_system_page.disabled_tooltip') : (deviceWithStatesWithLocation.length === 0 ? t('map.disabled_tooltip') : '')}>
                    <span>
                      <IconButton edge="end" color="inherit" disabled={deviceWithStatesWithLocation.length === 0} component={Link} to="/map">
                        <FontAwesomeIcon icon={faMapLocationDot} />
                      </IconButton>
                    </span>
                  </Tooltip>
                </Grid>
                <Grid item>
                  <IconButton edge="end" color="inherit" component={Link} to="/user" title={user?.nickName}>
                    <FontAwesomeIcon icon={faUser} />
                  </IconButton>
                </Grid>
                {/*TODO reactivate when English language is complete <Grid item style={{ flexDirection: 'column' }}>
                      <IconButton
                        edge="end"
                        color="inherit"
                        type="submit"
                        key={i18n.language}
                        onClick={() => { setNextLanguage() }}
                        disabled={Object.keys(langs).length < 2}>
                        {langs[i18n.language === "en" ? "hu" : "en"].flag}
                      </IconButton>
                    </Grid>*/}
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Routes>
        {/*Default route is login if no user signed in or monitor-list if there is one signed in and all the necessary infos are known. Otherwise, no default route is available */}
        {token === '' ?
          <Route
            path="/"
            element={<Navigate to='/login' state={{ from: location }} />} /> : (
            user !== undefined &&
            selectedMonitoringSystemDescriptor !== undefined &&
            selectedMonitoringSystemState !== undefined &&
            <Route
              path="/"
              element={<Navigate to='/monitor-list' state={{ from: location }} />} />)}
        <Route
          path="/login"
          element={token === '' ? <LoginPage onLogin={setToken} /> : (
            user !== undefined &&
            selectedMonitoringSystemDescriptor !== undefined &&
            selectedMonitoringSystemState !== undefined &&
            <Navigate to='/monitor-list' state={{ from: location }} />)} />
        <Route
          path="/monitor-list"
          element={token === '' ? <Navigate to='/login' state={{ from: location }} /> : (
            user !== undefined &&
            selectedMonitoringSystemDescriptor !== undefined &&
            selectedMonitoringSystemState !== undefined &&
            <MonitoringSystemPage
              token={token}
              user={user}
              selectedMonitoringSystemDescriptor={selectedMonitoringSystemDescriptor}
              selectedMonitoringSystemState={selectedMonitoringSystemState}
              updateMonitoringSystemDescriptorRequest={updateMonitoringSystemDescriptors}
              onLogout={() => setToken('')} />)} />
        <Route
          path="/verify"
          element={<VerifyPage token={token} requestLogout={() => setToken('')} />} />
        <Route
          path="/map"
          element={token === '' ?
            <Navigate to='/login' state={{ from: location }} /> :
            (user !== undefined &&
              selectedMonitoringSystemDescriptor !== undefined &&
              selectedMonitoringSystemState !== undefined &&
              (deviceWithStatesWithLocation ?
                <MapPage devicesWithState={deviceWithStatesWithLocation} /> :
                <Navigate to='/monitor-list' state={{ from: location }} />))} />
        <Route
          path="/user"
          element={token === '' ? <Navigate to='/login' state={{ from: location }} /> :
            (user !== undefined && <SettingsPage
              token={token}
              user={user}
              hasDevices={(ownMonitoringSystemDescriptor?.deviceDescriptors.length ?? 0) > 0}
              onRequestUserUpdate={updateUser}
              onLogout={() => setToken('')} />)} />
      </Routes>
    </div >
  );
}

export default App;
