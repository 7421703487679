import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton, List, ListItem, ListItemIcon, ListItemText, Popover, Typography } from "@material-ui/core";
import { RadioButtonChecked, RadioButtonUnchecked } from "@material-ui/icons";
import { useCallback, useMemo, useState } from "react";
import { Trans } from "react-i18next";

export type MonitoringSystemDescriptorForSelection = {
    id: number,
    name: string
}

interface MonitoringSystemSelectorProps {
    monitoringSystemDescriptorsForSelection: MonitoringSystemDescriptorForSelection[],
    selectedMonitoringSystemId: number | undefined,
    ownMonitoringSystemId: number | undefined,
    onSelectionChange: (selectedMonitoringSystemDescriptorId: number) => void
}

export const MonitoringSystemSelector = (props: MonitoringSystemSelectorProps): JSX.Element => {
    const { monitoringSystemDescriptorsForSelection: monitoringSystemDescriptors, selectedMonitoringSystemId: selectedMonitoringSystemDescriptorId, ownMonitoringSystemId: ownMonitoringSystemDescriptorId, onSelectionChange } = props;
    // State variables for the dropdown list
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [dropdownAnchor, setDropdownAnchor] = useState<HTMLElement | null>(null);

    const openDropdown = useCallback((event: React.MouseEvent<HTMLElement>) => {
        setIsDropdownOpen(true);
        setDropdownAnchor(event.currentTarget);
    }, []);

    const closeDropdown = useCallback(() => {
        setIsDropdownOpen(false);
    }, []);

    const handleMenuItemClick = useCallback((id: number) => {
        closeDropdown();
        onSelectionChange(id);
    }, [closeDropdown, onSelectionChange]);

    const selectedMonitoringSystemDescriptor = useMemo(() => {
        return monitoringSystemDescriptors.find(ms => ms.id === selectedMonitoringSystemDescriptorId);
    }, [monitoringSystemDescriptors, selectedMonitoringSystemDescriptorId]);

    const orderedMonitoringSystems = useMemo(() => {
        let result = monitoringSystemDescriptors.sort((ms1, ms2) => {
            //then own monitoring system should be shown
            if (ownMonitoringSystemDescriptorId !== undefined) {
                if (ms1.id === ownMonitoringSystemDescriptorId) {
                    return -1;
                }

                if (ms2.id === ownMonitoringSystemDescriptorId) {
                    return 1;
                }
            }

            //then alphabetical order matters
            if (ms1.name < ms2.name) {
                return -1;
            }

            if (ms1.name === ms2.name) {
                return 0;
            }

            return 1;
        });

        return result;
    }, [monitoringSystemDescriptors, ownMonitoringSystemDescriptorId])

    return (
        <div>
            <IconButton
                edge="end"
                color="inherit"
                onClick={openDropdown}
            >
                <FontAwesomeIcon icon={faChevronDown} style={{ marginRight: 8 }} />
                {selectedMonitoringSystemDescriptor !== undefined ? (
                    <Typography variant="body1" style={{ marginRight: 8 }}>
                        <Trans>{selectedMonitoringSystemDescriptor.name}</Trans>
                    </Typography>
                ) : (
                    <Typography variant="body1" style={{ color: '#888888', marginRight: 8 }}>
                        <Trans i18nKey={'select_mon_sys'}></Trans>
                    </Typography>
                )}
            </IconButton>
            <Popover
                open={isDropdownOpen}
                anchorEl={dropdownAnchor}
                onClose={closeDropdown}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <List>
                    {orderedMonitoringSystems.map((item, index) => (
                        <ListItem button key={index} onClick={() => (handleMenuItemClick(item.id))}>
                            <ListItemIcon>
                                {selectedMonitoringSystemDescriptorId === item.id ? <RadioButtonChecked /> : <RadioButtonUnchecked />}
                            </ListItemIcon>
                            <ListItemText primary={item.name} />
                        </ListItem>
                    ))}
                </List>
            </Popover>
        </div>
    );
}
